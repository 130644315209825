<template>
  <section>
    <TitleBar tabsParent="backoffice">Backoffice</TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(modifieLe)="{ item }">
          <DateTimeComponent :value="item.modifieLe"></DateTimeComponent>
        </template>
        <template #cell(valeur)="{ item }">
          <div style="word-wrap: break-word; max-width: 400px">
            {{ getValeur(item) }}
          </div>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import DateTimeComponent from "../../components/DateTimeComponent.vue";
import ReferentielService from "../../services/referentiel.service";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "GestionParametresView",
  components: {
    TitleBar,
    ErrorComponent,
    PaginatedTable,
    DateTimeComponent,
  },
  data() {
    return {
      error: null,
      pageable: {
        currentPage: 1,
        perPage: 10,
        sortBy: "codeParametre",
        sortDesc: false,
      },
      fields: [
        {
          key: "codeParametre",
          label: "Code",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "valeur",
          label: "Valeur",
          thStyle: { width: "200px" },
          sortable: true,
        },
        {
          key: "modifieLe",
          label: "Modifié le",
          sortable: true,
        },
        {
          key: "modifiePar",
          label: "Modifié par",
          sortable: true,
        },
      ],
      page: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        let response = await ReferentielService.getAllParametres();
        let sortProperty = this.fields.find(
          (f) => f.key === this.pageable.sortBy
        );
        this.page = UtilsService.readOnlyPage(
          response.data,
          this.pageable.currentPage - 1,
          this.pageable.perPage,
          UtilsService.sortByStringProperty(
            sortProperty.sortBy || sortProperty.key,
            this.pageable.sortDesc ? SortDirection.DESC : SortDirection.ASC
          )
        );
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    pageableChanged(pageable) {
      this.pageable = pageable;
      this.load();
    },
    getValeur(parametre) {
      return parametre.valeur.length > 100
        ? parametre.valeur.slice(0, 100) + "..."
        : parametre.valeur;
    },
  },
};
</script>
